import React from "react"
import Layout from "../../components/layout"

import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Products from "../../components/peaksuiteProducts"
import { StaticImage } from "gatsby-plugin-image"

const Banner = () => (
  <div>
    <div className="hero-banner peaksuite-wrap-hero">
      <div className="container">
        <div
          className="hero-banner-content text-center"
          style={{ padding: "50px 0px" }}
        >
          <div
            className="hero-title-wrap text-center"
            style={{ background: "#fff" }}
          >
            <ScrollAnimation animateIn="fadeInLeft" initiallyVisible={true}>
              <Link
                aria-label="link"
                to="/peaksuite/peaksuite-multi-file-uploader/"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="img-fluid"
                  src="../../../static/assets/productLogos/PS_multi-file-uploader.png"
                  alt="peakSUITE MULTI FILE UPLOADER"
                  width={400}
                  loading="lazy"
                  layout="constrained"
                />
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </div>
)
class ProductPeakSuite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "peakSuite | Multi File Uploader",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    localStorage.setItem("title", this.state.title)
  }
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="peakSUITE Multi File Uploader | Custom Add-Ons for Quickbase"
          description="Multi-File Uploader is a document upload tool within Quickbase that allows users to select multiple documents at a time and upload them to any record."
        />
        <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
          <section className="component pt-4 pb-0">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-12 peakSuite-product">
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <iframe
                        src="https://www.youtube.com/embed/ry26AXRS-Dw"
                        frameborder="0"
                        width="100%"
                        height="400"
                        className="mr-3 mb-3"
                        style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
                        title="uploader"
                      />
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <p>
                        The{" "}
                        <span className="uploader-color">
                          peakSUITE | Multi File Uploader{" "}
                        </span>
                        Currently, in Quickbase, a user can only upload one
                        document at a time. Multi-File Uploader is a document
                        upload tool within Quickbase that allows users to select
                        multiple documents at a time and upload to any record.
                        <br />
                        You can also upload or drag-and-drop files, rename
                        document titles and view the file type and size before
                        uploading. This is a great time-saving that will help
                        make your team more efficient.
                        {/* is a document upload tool, within Quickbase, which
                        allows a user to select multiple documents at a time and
                        upload to a record. Currently, in Quickbase, a user can
                        only upload one document at a time. This time saving
                        tool will be a great addition to all of your
                        applications. */}
                      </p>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <Link
                      to="/contact/"
                      onClick={this.handleSubmit}
                      className="btn btn-primary btn-arrow uploader-bg"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row peaksuite-products mt-5">
                <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
                  {" "}
                  <div className="return-btn-uploader">
                    <Link
                      to="/peaksuite/"
                      className="btn btn-primary-outline px-3"
                      style={{
                        color: "#941b80",
                        border: "1px solid #941b80",
                        fontSize: "18px",
                      }}
                    >
                      <i className="fa fa-caret-left mr-2" />
                      Return
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-9 col-12 prod-order-1">
                  <Products />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ProductPeakSuite
